// ======================================
// ROBOTO
// ======================================

@font-face {
	font-family: 'roboto_black';
	src: url('../fonts/roboto/roboto-black.eot');
	src: url('../fonts/roboto/roboto-black.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-black.woff2') format('woff2'), url('../fonts/roboto/roboto-black.woff') format('woff'), url('../fonts/roboto/roboto-black.ttf') format('truetype'), url('../fonts/roboto/roboto-black.svg#robotoblack') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_black-italic';
	src: url('../fonts/roboto/roboto-blackitalic.eot');
	src: url('../fonts/roboto/roboto-blackitalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-blackitalic.woff2') format('woff2'), url('../fonts/roboto/roboto-blackitalic.woff') format('woff'), url('../fonts/roboto/roboto-blackitalic.ttf') format('truetype'), url('../fonts/roboto/roboto-blackitalic.svg#robotoblack_italic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_bold';
	src: url('../fonts/roboto/roboto-bold.eot');
	src: url('../fonts/roboto/roboto-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-bold.woff2') format('woff2'), url('../fonts/roboto/roboto-bold.woff') format('woff'), url('../fonts/roboto/roboto-bold.ttf') format('truetype'), url('../fonts/roboto/roboto-bold.svg#robotobold') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_bold-italic';
	src: url('../fonts/roboto/roboto-bolditalic.eot');
	src: url('../fonts/roboto/roboto-bolditalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-bolditalic.woff2') format('woff2'), url('../fonts/roboto/roboto-bolditalic.woff') format('woff'), url('../fonts/roboto/roboto-bolditalic.ttf') format('truetype'), url('../fonts/roboto/roboto-bolditalic.svg#robotobold_italic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_italic';
	src: url('../fonts/roboto/roboto-italic.eot');
	src: url('../fonts/roboto/roboto-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-italic.woff2') format('woff2'), url('../fonts/roboto/roboto-italic.woff') format('woff'), url('../fonts/roboto/roboto-italic.ttf') format('truetype'), url('../fonts/roboto/roboto-italic.svg#robotoitalic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_light';
	src: url('../fonts/roboto/roboto-light.eot');
	src: url('../fonts/roboto/roboto-light.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-light.woff2') format('woff2'), url('../fonts/roboto/roboto-light.woff') format('woff'), url('../fonts/roboto/roboto-light.ttf') format('truetype'), url('../fonts/roboto/roboto-light.svg#robotolight') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_light-italic';
	src: url('../fonts/roboto/roboto-lightitalic.eot');
	src: url('../fonts/roboto/roboto-lightitalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-lightitalic.woff2') format('woff2'), url('../fonts/roboto/roboto-lightitalic.woff') format('woff'), url('../fonts/roboto/roboto-lightitalic.ttf') format('truetype'), url('../fonts/roboto/roboto-lightitalic.svg#robotolight_italic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_medium';
	src: url('../fonts/roboto/roboto-medium.eot');
	src: url('../fonts/roboto/roboto-medium.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-medium.woff2') format('woff2'), url('../fonts/roboto/roboto-medium.woff') format('woff'), url('../fonts/roboto/roboto-medium.ttf') format('truetype'), url('../fonts/roboto/roboto-medium.svg#robotomedium') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_medium-italic';
	src: url('../fonts/roboto/roboto-mediumitalic.eot');
	src: url('../fonts/roboto/roboto-mediumitalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-mediumitalic.woff2') format('woff2'), url('../fonts/roboto/roboto-mediumitalic.woff') format('woff'), url('../fonts/roboto/roboto-mediumitalic.ttf') format('truetype'), url('../fonts/roboto/roboto-mediumitalic.svg#robotomedium_italic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_regular';
	src: url('../fonts/roboto/roboto-regular.eot');
	src: url('../fonts/roboto/roboto-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-regular.woff2') format('woff2'), url('../fonts/roboto/roboto-regular.woff') format('woff'), url('../fonts/roboto/roboto-regular.ttf') format('truetype'), url('../fonts/roboto/roboto-regular.svg#robotoregular') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_thin';
	src: url('../fonts/roboto/roboto-thin.eot');
	src: url('../fonts/roboto/roboto-thin.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-thin.woff2') format('woff2'), url('../fonts/roboto/roboto-thin.woff') format('woff'), url('../fonts/roboto/roboto-thin.ttf') format('truetype'), url('../fonts/roboto/roboto-thin.svg#robotothin') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto_thin-italic';
	src: url('../fonts/roboto/roboto-thinitalic.eot');
	src: url('../fonts/roboto/roboto-thinitalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/roboto-thinitalic.woff2') format('woff2'), url('../fonts/roboto/roboto-thinitalic.woff') format('woff'), url('../fonts/roboto/roboto-thinitalic.ttf') format('truetype'), url('../fonts/roboto/roboto-thinitalic.svg#robotothin_italic') format('svg');
	font-weight: normal;
	font-style: normal; }





// ======================================
// ROBOTO CONDENSED
// ======================================

@font-face {
	font-family: 'roboto-condensed_bold';
	src: url('../fonts/roboto-condensed/robotocondensed-bold.eot');
	src: url('../fonts/roboto-condensed/robotocondensed-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-condensed/robotocondensed-bold.woff2') format('woff2'), url('../fonts/roboto-condensed/robotocondensed-bold.woff') format('woff'), url('../fonts/roboto-condensed/robotocondensed-bold.ttf') format('truetype'), url('../fonts/roboto-condensed/robotocondensed-bold.svg#roboto_condensedbold') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto-condensed_bold-italic';
	src: url('../fonts/roboto-condensed/robotocondensed-bolditalic.eot');
	src: url('../fonts/roboto-condensed/robotocondensed-bolditalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-condensed/robotocondensed-bolditalic.woff2') format('woff2'), url('../fonts/roboto-condensed/robotocondensed-bolditalic.woff') format('woff'), url('../fonts/roboto-condensed/robotocondensed-bolditalic.ttf') format('truetype'), url('../fonts/roboto-condensed/robotocondensed-bolditalic.svg#roboto_condensedbold_italic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto-condensed_italic';
	src: url('../fonts/roboto-condensed/robotocondensed-italic.eot');
	src: url('../fonts/roboto-condensed/robotocondensed-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-condensed/robotocondensed-italic.woff2') format('woff2'), url('../fonts/roboto-condensed/robotocondensed-italic.woff') format('woff'), url('../fonts/roboto-condensed/robotocondensed-italic.ttf') format('truetype'), url('../fonts/roboto-condensed/robotocondensed-italic.svg#roboto_condenseditalic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto-condensed_light';
	src: url('../fonts/roboto-condensed/robotocondensed-light.eot');
	src: url('../fonts/roboto-condensed/robotocondensed-light.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-condensed/robotocondensed-light.woff2') format('woff2'), url('../fonts/roboto-condensed/robotocondensed-light.woff') format('woff'), url('../fonts/roboto-condensed/robotocondensed-light.ttf') format('truetype'), url('../fonts/roboto-condensed/robotocondensed-light.svg#roboto_condensedlight') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto-condensed_light-italic';
	src: url('../fonts/roboto-condensed/robotocondensed-lightitalic.eot');
	src: url('../fonts/roboto-condensed/robotocondensed-lightitalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-condensed/robotocondensed-lightitalic.woff2') format('woff2'), url('../fonts/roboto-condensed/robotocondensed-lightitalic.woff') format('woff'), url('../fonts/roboto-condensed/robotocondensed-lightitalic.ttf') format('truetype'), url('../fonts/roboto-condensed/robotocondensed-lightitalic.svg#roboto_condensedlight_italic') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'roboto-condensed_regular';
	src: url('../fonts/roboto-condensed/robotocondensed-regular.eot');
	src: url('../fonts/roboto-condensed/robotocondensed-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-condensed/robotocondensed-regular.woff2') format('woff2'), url('../fonts/roboto-condensed/robotocondensed-regular.woff') format('woff'), url('../fonts/roboto-condensed/robotocondensed-regular.ttf') format('truetype'), url('../fonts/roboto-condensed/robotocondensed-regular.svg#roboto_condensedregular') format('svg');
	font-weight: normal;
	font-style: normal; }





// ======================================
// BERNIER
// ======================================

@font-face {
	font-family: 'bernier_distressed';
	src: url('../fonts/bernier/bernierdistressed-regular.eot');
	src: url('../fonts/bernier/bernierdistressed-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/bernier/bernierdistressed-regular.woff2') format('woff2'), url('../fonts/bernier/bernierdistressed-regular.woff') format('woff'), url('../fonts/bernier/bernierdistressed-regular.ttf') format('truetype'), url('../fonts/bernier/bernierdistressed-regular.svg#bernier_distressedregular') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'bernier_regular';
	src: url('../fonts/bernier/bernierregular-regular.eot');
	src: url('../fonts/bernier/bernierregular-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/bernier/bernierregular-regular.woff2') format('woff2'), url('../fonts/bernier/bernierregular-regular.woff') format('woff'), url('../fonts/bernier/bernierregular-regular.ttf') format('truetype'), url('../fonts/bernier/bernierregular-regular.svg#bernier_regularregular') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'bernier_shade';
	src: url('../fonts/bernier/berniershade-regular.eot');
	src: url('../fonts/bernier/berniershade-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/bernier/berniershade-regular.woff2') format('woff2'), url('../fonts/bernier/berniershade-regular.woff') format('woff'), url('../fonts/bernier/berniershade-regular.ttf') format('truetype'), url('../fonts/bernier/berniershade-regular.svg#bernier_shaderegular') format('svg');
	font-weight: normal;
	font-style: normal; }
