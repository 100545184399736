// =================================
// Clearfix
// =================================
%clearfix {
	&::before,
	&::after {
		content: "";
		display: table; }
	&::after {
		clear: both; } }



// ===================================
// Fullheight sections
// ===================================
%fullheight {
	height: 100%;
	min-height: 650px;
	overflow: hidden;

	// calculating pusher height for footer
	&.last-section {
		height: calc(100% + #{$footer_height - 190});
		min-height: calc(650px + #{$footer_height - 190});
		@include media(s) {
			height: calc(100% + #{$footer-height_small - 190});
			min-height: calc(650px + #{$footer-height_small - 190}); }
		@include media(xs) {
			height: calc(100% + #{$footer-height_xsmall - 190});
			min-height: calc(650px + #{$footer-height_xsmall - 190}); } } }
