// =================================
// Mixins
// =================================
@mixin media($screen) {
	// "DO" is "Descktop Only" fallback to prevent mobile loading
	$screen_sizes: (l: 1200px, m: 992px, s: 768px, xs: 480px, xxs: 320px, DO: 769px);
	@if $screen == DO {
		@media only screen and (min-width : #{map-get($screen_sizes, $screen)}) {
			@content; } }
	@else {
		@media only screen and (max-width : #{map-get($screen_sizes, $screen)}) {
			@content; } } }
