#preloader {
	// display: none
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 99999;
	background-color: $maincolor;
	text-align: center;
	// background-image: url("/assets/img/theme_mountains/preloader_bg.jpg")
	background-position: center center;
	background-size: cover;
	&__progress {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 144px;
		height: 144px;
		margin: auto;
		z-index: 999999; }
	#preloader-svg {
		&__percentage {
			font-family: "Arial", sans-serif;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			color: $white;
			font-size: em(24);
			animation: blink $transition-time_medium infinite; }
		&__img {
			.bar {
				transition: stroke-dashoffset $transition-time_medium;
				@include media(xs) {
					transition: none; } } } }
	#looped-bar {
		border: 4px solid transparent;
		border-top-color: $white;
		border-radius: 50%;
		display: block;
		width: 60px;
		height: 60px;
		margin-top: -30px;
		margin-left: -30px;
		position: absolute;
		top: 50%;
		left: 50%;
		animation: preloader $transition-time linear infinite; } }



@keyframes preloader {
	0% {
		transform: rotate(0);
		opacity: .5; }
	50% {
		opacity: 1; }
	100% {
		transform: rotate(360deg);
		opacity: .5; } }

@keyframes blink {
	0% {
		opacity: .5; }
	50% {
		opacity: 1; }
	100% {
		opacity: .5; } }
