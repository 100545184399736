// =================================
// Chris Coyier border-box reset
// =================================
html {
	box-sizing: border-box; }

*, *:before, *:after {
	box-sizing: inherit; }





// =================================
// Page init
// =================================
html,
body {
	height: 100%;
	min-width: 320px;
	position: relative;
	font-size: 100%;
	font-family: "Roboto_medium", sans-serif;
	transition: all $transition-time; }


// Show scrollbar on every page
html {
	overflow-x: hidden;
	overflow-y: scroll; }


// Last section on the page
.last-section {
	padding-bottom: $footer-height;
	margin-bottom: -$footer-height;
	@include media(s) {
		padding-bottom: $footer-height_small;
		margin-bottom: -$footer-height_small; }
	@include media(xs) {
		padding-bottom: $footer-height_xsmall;
		margin-bottom: -$footer-height_xsmall; } }


.container {
	max-width: 1800px;
	margin: 0 auto;
	&_padded {
		padding: 25px 20px; }
	&_fullwidth {
		max-width: none; } }


.sprites-container {
	display: none; }





// =================================
// Typography
// =================================

h1, h2, h3, h4, h5, h6 {
	font-family: "bernier_regular", sans-serif;
	color: $text-color;
	line-height: 1;
	font-weight: normal;
	margin: 0;
	text-align: center;
	position: relative; }

h1, h2 {
	font-size: em(63,16);
	@include media(m) {
		font-size: em(49,16); }
	@include media(xs) {
		font-size: em(35,16); } }

h3 {
	font-size: em(35,16);
	@include media(m) {
		font-size: em(30,16); }
	@include media(xs) {
		font-size: em(21,16); } }

h4, h5, h6 {
	font-size: em(26,16);
	@include media(m) {
		font-size: em(24,16); }
	@include media(xs) {
		font-size: em(21,16); } }

.hero-heading {
	line-height: 1.2;
	text-shadow: 0 0 10px rgba($gray, .6); }

.sidestripes {
	padding: 0 42px;
	display: inline-block;
	&::before,
	&::after {
		color: $stripes-color;
		content: "";
		position: absolute;
		top: .5em;
		margin-top: -2.5px;
		height: 5px;
		width: 22px;
		display: inline-block;
		vertical-align: middle;
		background-color: $gray_light;
		opacity: .8; }
	&::before {
		left: 0; }
	&::after {
		right: 0; } }


.svg-heading {
	position: relative;
	display: block;
	padding: 65px 15px;
	text-align: center;
	&__text {
		position: relative;
		width: 380px;
		z-index: 3;
		@include media(m) {
			width: auto; } }
	&__img {
		position: absolute;
		fill: invert($white);
		z-index: 0;
		top: 50%;
		left: 50%;
		width: 100%;
		height: auto;
		transform: translate(-50%,-55%);
		opacity: .07; } }






// =================================
// Elements
// =================================
img {
	max-width: 100%;
	height: auto; }

button {
	border: none;
	outline: none;
	background: none;
	cursor: pointer; }

// Chevron buttons
.go-down,
.go-up {
	color: $white;
	font-size: em(36);
	opacity: .7;
	transition: all $transition-time;
	padding: 0;
	&:hover {
		opacity: 1; } }

.go-down {
	position: absolute;
	z-index: 1;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	&:hover {
		padding-top: 10px;
		bottom: 30px; } }

.go-up {
	display: block;
	position: relative;
	margin: 70px auto;
	width: 50px;
	height: 50px;
	font-size: em(40);
	text-shadow: 0 0 10px rgba(invert($white), .5); }


.avatar {
	margin: 0 auto;
	width: 137px;
	height: 137px;
	border-radius: 50%;
	overflow: hidden;
	img {
		width: 100%;
		display: block; } }


.social-links {
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	z-index: 5;
	&__item {
		display: inline-block;
		margin: 0 10px;
		text-align: center;
		@include media(xs) {
			margin: 0 6px; }
		&:first-child {
			margin-left: 0; }
		&:last-child {
			margin-right: 0; }
		&_welcome {
			.social-links__link {
				color: $maincolor; } }
		&_circle {
			margin: 0 5px;
			.social-links__link {
				border: 2px solid $white;
				border-radius: 50%;
				font-size: em(18);
				line-height: 34px;
				width: 38px;
				height: 38px;
				&:hover {
					background-color: $white;
					&::before {
						color: $text-color; } } } }
		&_svg {
			margin: 0 5px; } }

	&__link {
		position: relative;
		color: $white;
		text-decoration: none;
		font-size: em(26,16);
		line-height: 1;
		opacity: .5;
		transition: opacity $transition-time;
		@include media(xs) {
			font-size: em(24,16); }
		&:hover {
			opacity: 1; } }

	&__svg-link {
		text-decoration: none;
		svg {
			width: 38px;
			height: 38px;
			border-radius: 50%;
			box-shadow: inset 0 0 0 1px $white;
			overflow: hidden; }
		.mask__path {
			transition: all $transition-time; }
		.mask__rect_inner,
		.mask__rect_outer {
			fill: $white;
			transition: all $transition-time; }
		&:hover {
			.mask__path {
				fill: $white; }
			.mask__rect_inner {
				fill: transparent; } } } }





// =================================
// Page Links / Form buttons
// =================================
.form-buttons {
	padding: 0;
	margin: 0;
	list-style: none;
	display: table;
	width: 100%;
	position: relative;
	&__button {
		display: table-cell;
		border-right: 2px solid rgba(lighten($maincolor, 15%), .851);
		&:last-child {
			border: none; }
		&_even {
			width: 50%; } }

	&__button-content {
			display: block;
			width: 100%;
			padding: 10px 15px 12px;
			color: $white;
			background-color: rgba($maincolor, .7);
			text-decoration: none;
			transition: all $transition-time;
			&:hover {
				background-color: rgba($maincolor, .4); } } }





// =================================
// Mountains positioning
// =================================
.parallax-wrapper {
	position: fixed;
	overflow: hidden;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	min-height: 650px;
	z-index: -1;
	transform: translateZ(0); }

#scene, .layer {
	display: block;
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	transform: translateZ(0); }

#scene {
	position: relative;
	overflow: hidden; }

.layer {
	position: absolute;
	.layer-bg {
		transform: translate3d(0,0,0);
		backface-visibility: hidden;
		position: absolute;
		width: 110%;
		height: 110%;
		left: -5%;
		top: -5%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center 40%;
		@include media(s) {
			width: 120%;
			height: 120%;
			left: -10%;
			top: -10%; } }

	@include media(DO) {
		@for $i from 0 to 10 {
			.layer-bg_#{$i} {
				background-image: url("/assets/img/theme_mountains/parallax/layer_#{$i}.png"); } } }
	@include media(s) {
		.layer-bg_5 {
			background-image: url("/assets/img/theme_mountains/parallax_mobile.png"); } } }
